// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts.css';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FpjsProvider
      loadOptions={{
        apiKey: "jtu85DZzdTsG1kBZ8EBM"
      }}
    >
      <App />
    </FpjsProvider>
  </React.StrictMode>
);

reportWebVitals();