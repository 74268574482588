// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, NavLink, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import TherapySessionPage from './TherapySessionPage';
import HomePage from './HomePage';
// import LoginInfoPage from './LoginInfoPage';
import { ChatDots, CardList } from 'react-bootstrap-icons';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import Footer from './Footer';
import AITherapyNeedPage from './AITherapyNeedPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import CookiePolicyPage from './CookiePolicyPage';
import TermsAndConditionsPage from './TermsAndConditionsPage';
import DisclaimerPage from './DisclaimerPage';
import AcceptableUsePolicyPage from './AcceptableUsePolicyPage';
import { mouseflow } from 'react-mouseflow';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [therapySessionType, setTherapySessionType] = useState('voice');
  const [userState, setUserState] = useState({
    messages: [],
    voiceName: 'nova', // default value for voiceName
  });

  const { data } = useVisitorData(
    { extendedResult: true },
    { immediate: true }
  );

  useEffect(() => {
    if (data?.visitorId) {
      setUserState((prevState) => ({
        ...prevState,
        visitorId: data.visitorId,
      }));
    }
  }, [data]);

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      mouseflow.initialize('938e4828-fed5-4d28-843a-3cfb21b2cff4');
    }
  }, []);

  return (
    <BrowserRouter>
    <ScrollToTop />
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand as={NavLink} to="/" className="brand-logo">FrohAI</Navbar.Brand>
          <Nav className="ms-auto">
            <NavLink to="/therapy-session" className="nav-link">
              <ChatDots size={24} />
            </NavLink>
            <NavLink to="/thesis" className="nav-link">
              <CardList size={24} />
            </NavLink>
            {/* <NavLink to="/login" className="nav-link">
              <ShieldLock size={24} />
            </NavLink> */}
          </Nav>
        </Container>
      </Navbar>
      <Routes>
        {/* <Route path="/" element={<HomePage />} /> */}
        <Route path="/" element={<HomePage userState={userState} setUserState={setUserState} />} />
        {/* <Route
          path="/issues"
          element={<IssuesPage userState={userState} setUserState={setUserState} />}
        /> */}
        <Route
          path="/therapy-session"
          element={
            <TherapySessionPage
              therapySessionType={therapySessionType}
              setTherapySessionType={setTherapySessionType}
              userState={userState}
              setUserState={setUserState} /> } />
        <Route path="/thesis" element={<AITherapyNeedPage />} />
        {/* <Route path="/login" element={<LoginInfoPage />} /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/cookie-policy" element={<CookiePolicyPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        <Route path="/disclaimer" element={<DisclaimerPage />} />
        <Route path="/acceptable-use-policy" element={<AcceptableUsePolicyPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;